 <template>
  <v-container fluid>
    <h1>Component 2</h1>
    <h3>Access token</h3>
    <p>{{$route.query.access_token}}</p>
    <v-switch v-model="toggle"></v-switch>
    <p>{{number}}</p>
  </v-container>
</template>
 
 
 <script>
export default {
  name: "Component2",
  props: {},
  components: {},

  data() {
    return {
      toggle: false,
      number: 0,
    };
  },

  methods: {},

  computed: {},

  watch: {
    toggle: function (/*newValue*/) {
      if (window.flutter_inappwebview) {
        window.flutter_inappwebview
          .callHandler("mySum", 12, 2, 50)
          .then((result) => {
            // get result from Flutter side. It will be the number 64.
            console.log(result);
            this.number++;
          });
      }
    },
  },
};
</script>

 
 <style lang='scss' scoped>
</style>